import React from "react";
import { Box, Typography, Link } from "@mui/material";
import Layout from "../Util/Layout";
import UNLEngineering from "../../Assets/UNLEngineering.jpg";
import GeorgiaTech from "../../Assets/GeorgiaTech.png";


const EducationPage = () => {
  return (
    <Layout>
      <Box p={3}>
        <Typography variant="h3" gutterBottom>
          My Education Journey
        </Typography>

        <Typography variant="h5" gutterBottom>
          Georgia Tech
        </Typography>
        <Typography paragraph>
          I am currently a part-time graduate student at Georgia Tech pursuing a
          <strong> Masters of Computer Science in Interactive Intelligence</strong>. Interactive Intelligence
          is essentially the intersection of machine learning and cognitive science, so I am
          taking courses on AI, ML, cognitive science, and more. If you are interested, you can
          take a look at the degree specialization <Link href="https://omscs.gatech.edu/specialization-interactive-intelligence" target="_blank" rel="noopener noreferrer">here</Link>.
        </Typography>
        <Box my={3}>
          <img
            src={GeorgiaTech}
            alt="GATECH Logo"
            style={{
              maxWidth: "70%",
              height: "auto",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          />
        </Box>
        <Typography variant="h6" gutterBottom>
          Highlighted Course Work
        </Typography>
        <Typography paragraph>
          <Link href="https://lucylabs.gatech.edu/kbai/" target="_blank" rel="noopener noreferrer"><strong>Knowledge Based AI - Georgia Tech</strong></Link>:
          This course is designed to teach students to build AI-Agents that are capable of human level cognition, and use these agents to explore the subject of human cognition.
          It is heavily example and project based, with a course project of designing an agent that can solve <Link href="https://arcprize.org/arc" target="_blank" rel="noopener noreferrer">ARC-AGI</Link> problems.

          <Typography variant="h5" gutterBottom>
            University of Nebraska-Lincoln
          </Typography>
          <Typography paragraph>
            I received my <strong>Bachelors of Science in Software Engineering</strong> from the
            University of Nebraska-Lincoln in 2024.  My focus area
            was Artificial Intelligence & Machine Learning, including classes such as
            "Artificial Intelligence", "Machine Learning", and "Constraint Processing".
            The degree page can be found <Link href="https://computing.unl.edu/software-engineering/" target="_blank" rel="noopener noreferrer">here</Link>.
          </Typography>
          <Box my={3}>
            <img
              src={UNLEngineering}
              alt="UNL Logo"
              style={{
                maxWidth: "70%",
                height: "auto",
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            />
          </Box>

          <Typography variant="h6" gutterBottom>
            Highlighted Course Work
          </Typography>

        </Typography>
        <Typography paragraph>
          <strong>Introduction to Machine Learning - UNL</strong>: This is a survey course of the field of machine learning. Topics included theoretical and empirical analyses of decision trees, artificial neural
          networks, Bayesian classifiers, genetic algorithms, instance-based classifiers and reinforcement learning.
        </Typography>
        <Typography paragraph>
          <strong>Foundations of Constraint Processing - UNL</strong>: This graduate-level course focused on foundations of constraint satisfaction, its basic mechanisms
          (search, backtracking, consistency-checking algorithms, etc), and constraint programming languages. There was a heavy focus on implementing methods outlined in modern research papers.
        </Typography>
      </Box>
    </Layout>
  );
};

export default EducationPage;
