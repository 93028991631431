import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { NavLink } from "react-router-dom";

const NavigationBar = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const menuItems = [
    { label: "Home", path: "/" },
    { label: "Articles/Blog", path: "/articles" },
    { label: "Engineering Experience", path: "/engineering-experience" },
    { label: "Education", path: "/education" },
    { label: "Personal Life", path: "/personal-life" },
    { label: "Contact", path: "/contact" },
    { label: "LinkedIn", path: "https://www.linkedin.com/in/nick-colleran/" },
  ];

  const handleDrawerToggle = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const renderMobileMenu = () => (
    <Drawer anchor="left" open={mobileMenuOpen} onClose={handleDrawerToggle}>
      <List>
        {menuItems.map((item, index) => (
          <ListItem
            button
            key={index}
            component={NavLink}
            to={item.path}
            onClick={() => setMobileMenuOpen(false)}
          >
            <ListItemText primary={item.label} />
          </ListItem>
        ))}
      </List>
    </Drawer>
  );

  return (
    <AppBar position="static" color="primary">
      <Toolbar>
        {isMobile && (
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
        )}
        {isMobile
          ? renderMobileMenu()
          : menuItems.map((item, index) => (
            <Button
              key={index}
              color="inherit"
              component={NavLink}
              to={item.path}
              style={({ isActive }) => ({
                backgroundColor: isActive
                  ? "rgba(255, 255, 255, 0.12)"
                  : "inherit",
              })}
            >
              {item.label}
            </Button>
          ))}
      </Toolbar>
    </AppBar>
  );
};

export default NavigationBar;
