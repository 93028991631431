import React from "react";
import { Box, Typography } from "@mui/material";
import Layout from "../Util/Layout";
import InventreeToolkit from "../../Assets/InventreeToolkit.png";

const InventreeToolkitPage = () => {
  return (
    <Layout>
      <Box p={3}>
        <Typography variant="h3" gutterBottom>
          InvenTree Toolkit - Drone Amplified
        </Typography>

        <Typography variant="h4" color="textSecondary" paragraph>
          My Role: Solo Developer
        </Typography>
        <Typography variant="h4" color="textSecondary" paragraph>
          Project Result: Used In Production
        </Typography>

        {/* Project Description */}
        <Typography variant="h5" gutterBottom>
          Project Overview
        </Typography>
        <Typography paragraph>
          InvenTree Toolkit is an inventory management tool suite that automates
          everyday tasks for administrative workers. I have developed it to
          compensate for some shortcomings in the InvenTree Web Interface by
          tapping into the open source InvenTree APIs. Some of the uses include
          automating recursive build creation and generating internal part
          numbers. It has been estimated by my superiors that this tool saves an
          excess of $1000 per week in labor costs.
        </Typography>

        <Box my={3}>
          <img
            src={InventreeToolkit}
            alt="Inventree Toolkit Screenshot"
            style={{
              maxWidth: "100%",
              height: "auto",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          />
        </Box>

        {/* Technologies Used */}
        <Typography variant="h5" gutterBottom>
          Technologies Used
        </Typography>
        <ul>
          <li>
            <Typography>
              <strong>Python:</strong> This application is driven by Python. I
              took advantage of the data processing capabilities of Python to
              implement quick recursive logic and a functional GUI.
            </Typography>
          </li>
          <li>
            <Typography>
              <strong>PYQT5:</strong> Used for the front-end of the application,
              PYQT5 allows the application GUI to be user-friendly. The QThreads
              that come with PYQT5 allow API calls and data processing to be
              done outside of the main GUI loop to provide a seamless and smooth
              user experience.
            </Typography>
          </li>
          <li>
            <Typography>
              <strong>Open Source InvenTree API:</strong> The application
              integrates with the open-source InvenTree API to facilitate a wide
              range of inventory management functions.
            </Typography>
          </li>
        </ul>

        {/* What I Learned */}
        <Typography variant="h5" gutterBottom>
          What I Learned
        </Typography>
        <Typography paragraph>
          Reflecting on the creation of this project reveals multiple key
          insights, particularly in the areas of integrating with open-source
          projects, managing data types, handling poorly documented APIs, and
          working on HR software components. Here are a few of the key
          takeaways:
        </Typography>
        <Typography paragraph>
          <ul>
            <li>
              <strong>Integration with Open-Source Projects:</strong> Gained an
              understanding of various licenses and actively participated in
              community discussions.
            </li>
            <li>
              <strong>Managing Data Types:</strong> Focused on ensuring
              consistency and robust data validation for data integrity.
            </li>
            <li>
              <strong>Navigating Poorly Documented APIs:</strong> Employed
              reverse engineering and leveraged community forums for insights.
            </li>
            <li>
              <strong>Development of HR Software Components:</strong>{" "}
              Prioritized user-friendly interfaces, accessibility, and strong
              security for sensitive data.
            </li>
            <li>
              <strong>Importance of Effective Communication:</strong>{" "}
              Implemented agile methodologies and problem-solving strategies
              throughout the project.
            </li>
            <li>
              <strong>Addressing Cross-Platform Compatibility:</strong>{" "}
              Continuously balanced functionality and performance, highlighting
              the dynamic nature of software development.
            </li>
          </ul>
        </Typography>
        <Typography paragraph>
          Through the course of developing this tool, these experiences not only
          contributed to the technical development of the project, but also
          provided valuable lessons in full stack development, project
          management, and continuous learning.
        </Typography>
      </Box>
    </Layout>
  );
};

export default InventreeToolkitPage;
