import React from "react";
import { Box, Typography, Link } from "@mui/material";
import Layout from "../Util/Layout";
import ALA_fam from "../../Assets/ALA_fam.jpg";

const AlanPage = () => {
  return (
    <Layout>
      <Box p={3}>
        <Typography variant="h3" gutterBottom>
          ALAN - The ALA Navigator
        </Typography>

        <Typography variant="h4" color="textSecondary" paragraph>
          My Role: Development Team
        </Typography>
        <Typography variant="h4" color="textSecondary" paragraph>
          Project Result: Deployed for R&D
        </Typography>

        {/* Project Description */}
        <Typography variant="h5" gutterBottom>
          Project Overview
        </Typography>
        <Typography paragraph>
          ALAN is a full autonomy stack designed to run autonomous feed trucks in a production environment. This is the flagship product of ALA Engineering,
          and will be used to guide proprietary cabinless feed trucks. On this project, I was the second software hire on a team that has grown steadily since,
          and I work directly under the mentorship of an experienced principle autonomy engineer to design, implement, and test level 3 autonomous system functionality.
          My work so far has included implementing vision processing pipelines, building systems to fuse and utilize massive amounts of sensor data, and drawing meaningful conclusions
          from data in multiple forms. You can read more about the project and ALA Engineering as a company <Link href="https://ala.engineering/ala-navigator" target="_blank" rel="noopener noreferrer"> here</Link>
        </Typography>

        <Box my={3}>
          <img
            src={ALA_fam}
            alt="ALA Engineering Fellow Employees"
            style={{
              maxWidth: "100%",
              height: "auto",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          />
        </Box>

        {/* Technologies Used */}
        <Typography variant="h5" gutterBottom>
          Technologies Used in the Project
        </Typography>
        <ul>
          <li>
            <Typography>
              <strong>ROS2:</strong> Robot Operating System 2 is the leading set of software libraries and tools for building robotic applications.
            </Typography>
          </li>
          <li>
            <Typography>
              <strong>C++:</strong> The low level control and high safety ratings of C++ make it a great choice for this application.
            </Typography>
          </li>
          <li>
            <Typography>
              <strong>AI/ML Tools:</strong> Tools such as Tensorflow, Torch, Ultralytics, and more are utilized to build scalable vision processing models.
            </Typography>
          </li>
        </ul>
        <Typography paragraph>
          These are just a few of the technologies that allow us to create the future of agriculture automation.
        </Typography>

        {/* What I Learned */}
        <Typography variant="h5" gutterBottom>
          What I Learned
        </Typography>
        <Typography paragraph>
          This project has been perhaps the biggest and most valueable learning experience of my career. Working with a smaller start-up company allows
          me to be more hands on than I could have ever imagined. One big takeaway so far has been that safety always has to be a massive consideration in
          robotics. Our excellent director of safety and principle engineer dedicate so much time to crafting adequate redundancy and safety features that
          I have never seen as someone from a more amature robotics background. As far as technical knowledge goes, I can not even begin to describe everything
          this project has taught me. Some highlights have been learning to take a vision processing model from the first unlabeled image all the way to
          production, learning how to interface with vehicles through CAN, and learning how to design and implement a ROS2 architecture.
        </Typography>

      </Box>
    </Layout>
  );
};

export default AlanPage;
