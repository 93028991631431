import React from "react";
import { Box, Typography, Grid, Paper } from "@mui/material";
import Layout from "../Util/Layout";
import MissionReviewGraph from "../../Assets/MissionReviewGraph.png";
import MissionReviewLogin from "../../Assets/MissionReviewLogin.png";

const MissionReviewPage = () => {
  return (
    <Layout>
      <Box p={3}>
        <Typography variant="h3" gutterBottom>
          Mission Review - Drone Amplified
        </Typography>

        <Typography variant="h4" color="textSecondary" paragraph>
          My Role: Developer
        </Typography>
        <Typography variant="h4" color="textSecondary" paragraph>
          Project Result: Used In Production
        </Typography>

        {/* Project Description */}
        <Typography variant="h5" gutterBottom>
          Project Overview
        </Typography>
        <Typography paragraph>
          This AWS serverless application allows for easy drone flight data
          sharing and troubleshooting leveraging the AWS tool-suite. S3 is used
          to manage large amounts of data in multiple forms, and lambda
          functions are called for data manipulation. The front-end is a custom
          React application built from the ground up using Material UI.
          Currently, the team is investigating integration of machine learning
          to provide insights into failed flights. This project represents a
          breakthrough into a completely new market space for my company.
        </Typography>

        <Box my={3}>
          <img
            src={MissionReviewLogin}
            alt="Mission Review Login"
            style={{
              maxWidth: "100%",
              height: "auto",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          />
        </Box>
        <Box my={3}>
          <img
            src={MissionReviewGraph}
            alt="Mission Review Graph"
            style={{
              maxWidth: "100%",
              height: "auto",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          />
        </Box>

        {/* Technologies Used */}
        <Typography variant="h5" gutterBottom>
          Technologies Used in Mission Review
        </Typography>
        <Typography paragraph>
          Mission Review is a sophisticated application built on a serverless
          architecture, primarily utilizing AWS services and React. Key
          technologies include:
        </Typography>
        <ul>
          <li>
            <Typography>
              <strong>AWS Lambda:</strong> Powers the backend, offering
              scalability and high availability without traditional server
              management.
            </Typography>
          </li>
          <li>
            <Typography>
              <strong>Serverless Framework:</strong> Facilitates deployment and
              integration, simplifying the orchestration of AWS services.
            </Typography>
          </li>
          <li>
            <Typography>
              <strong>Amazon Cognito:</strong> Manages secure user
              authentication and supports different user roles for effective
              access control.
            </Typography>
          </li>
          <li>
            <Typography>
              <strong>Amazon DynamoDB:</strong> A NoSQL database used for
              storing and retrieving flight data efficiently.
            </Typography>
          </li>
          <li>
            <Typography>
              <strong>React:</strong> Employed for building a dynamic and
              responsive user interface.
            </Typography>
          </li>
        </ul>
        <Typography paragraph>
          Additionally, the project leverages various Serverless Framework
          plugins like Webpack for packaging, Offline Plugin for local testing,
          Scripts Plugin for workflow automation, and S3 Deploy Plugin for
          managing static content.
        </Typography>
        {/* What I Learned */}
        <Typography variant="h5" gutterBottom>
          What I Learned
        </Typography>
        <Typography paragraph>
          Throughout the development of the Mission Review project, I gained
          invaluable insights and skills in several key areas of software
          development. My journey through this project deepened my understanding
          of full-stack development, emphasizing the integration of front-end
          and back-end technologies to create a seamless user experience.
        </Typography>
        <Typography paragraph>
          <strong>
            Working with AWS services was a pivotal part of my learning.
          </strong>{" "}
          I explored various aspects of cloud computing, gaining practical
          experience in managing and{" "}
          <strong>deploying serverless applications using AWS Lambda</strong>.
          This experience enhanced my ability to design scalable,
          high-performance solutions in the cloud.
        </Typography>
        <Typography paragraph>
          <strong>
            Troubleshooting played a significant role in this project, teaching
            me to effectively identify and resolve issues.
          </strong>{" "}
          I learned to approach problems methodically, analyzing the system's
          behavior, and applying logical problem-solving techniques. This not
          only improved the project's stability but also honed my skills in
          critical thinking and
          <strong> solution-oriented approaches</strong>.
        </Typography>
        <Typography paragraph>
          Overall, the Mission Review project was a comprehensive learning
          experience that solidified my expertise in full-stack development and
          cloud services, while also strengthening my troubleshooting and
          analytical abilities.
        </Typography>

        {/* Application Features */}
        <Typography variant="h5" gutterBottom>
          Features and Functionality
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Paper elevation={3} p={2}>
              <Typography variant="h6">Large Scale Data Management</Typography>
              <Typography paragraph>
                The application is capable of receiving and processing very
                large amounts of data in nearly any form, storing them in S3.
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper elevation={3} p={2}>
              <Typography variant="h6">Data Visualization</Typography>
              <Typography paragraph>
                Given data in the form of ULG files, the application can plot
                various data points from a drone flight against time.
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper elevation={3} p={2}>
              <Typography variant="h6">Profile Management</Typography>
              <Typography paragraph>
                Cognito is used to manage profiles, allowing individual users to
                access only their own stored files.
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Paper elevation={3} p={2}>
              <Typography variant="h6">User Connection</Typography>
              <Typography paragraph>
                Users in the same group can share data between accounts if they
                so choose, and admins can be assigned for a given group.
              </Typography>
            </Paper>
          </Grid>
          {/* Add more features as needed */}
        </Grid>

        {/* Additional Sections */}
        {/* Consider adding sections like 'Challenges Faced', 'Future Improvements', etc. */}
      </Box>
    </Layout>
  );
};

export default MissionReviewPage;
